<template>
    <b-container fluid>
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Händlerbestellung bearbeiten:</h3>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Stammdaten</h3>
                            </div>
                            <div class="card-body" v-if="notEmptyObject(order.dealer)">
                                {{ order.dealer.name }}<br>
                                {{ order.dealer.street }} {{ order.dealer.house_number }}<br>
                                {{ order.dealer.zipcode }} {{ order.dealer.city }}<br>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Lieferdatum</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Lieferdatum</label>
                                    <input type="date" class="form-control form-control-sm" v-model="order.wishdate">
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Allgemeine Infos</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Infos an den Lieferanten</label>
                                    <textarea class="form-control form-control-sm" v-model="order.comment" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Lieferanten Infos</h3>
                            </div>
                            <div class="card-body">
                                <h6><strong>Händler:</strong> {{ order.dealer.name }}</h6>
                                <!-- <h6>
                                    <template v-if="order.distributor.skonto != null">
                                        <strong>Skonto:</strong> {{ order.distributor.skonto / 100 | toPercent }} 
                                    </template>

                                    <template v-if="order.distributor.skonto == null">
                                        <strong>Skonto:</strong> - Kein Skonto -
                                    </template>
                                </h6> -->
                                <!-- <h6>
                                    <template v-if="order.distributor.min_order_amount != null">
                                        <strong>Mindestbestellwert:</strong> {{ order.distributor.min_order_amount | toCurrency }}
                                    </template>

                                    <template v-if="order.distributor.min_order_amount == null">
                                        <strong>Mindestbestellwert:</strong> - Kein Mindestbestellwert -
                                    </template>
                                </h6> -->

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="order.dealer.orderinfo != null">
                    <div class="col-md-12">
                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <h2 class="card-title">Bestellinfos</h2>
                            </div>
                            <div class="card-body">
                                <span v-html="order.dealer.orderinfo"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-primary card-outline" style="height: 45vh">
                            <div class="card-header">
                                <h2 class="card-title">Bestellbare Artikel</h2>
                                <div class="card-tools">
                                    <div class="input-group input-group-sm">
                                        <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
                                        <button type="submit" class="btn btn-default btn-sm" @click.prevent="getAllArticles">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>Artikelname</th>
                                            <th>Hersteller</th>
                                            <th>Preis</th>
                                            <th>Flags</th>
                                        </tr>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>

                                            <th>
                                                <select v-model="params.producer_id" class="form-control form-control-sm">
                                                    <option :value="''">-- Filter Hersteller --</option>
                                                    <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.number }} - {{ producer.name  }}</option>
                                                </select>
                                            </th>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in articles.data" :key="article.id" :class="article.article.active == 0 ? 'table-danger' : ''" @dblclick="addArticle(index)">
                                            <td>{{ article.ordernumber }}</td>
                                            <td>{{ generateName(index) }}</td>
                                            <td>{{ article.article.producer.name }}</td>
                                            <td>{{ article.price | toCurrency}}</td>
                                            <td>
                                                <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                                <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                                <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                                <span class="ml-2 badge bg-warning" v-if="article.article.seasonal == 1">Saison </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="clearfix align-middle card-footer">
                                <div class="row">
                                    <div class="col-md-12">
                                        *rot hinterlegte Artikel sind deaktivierte Artikel. Sie können aber bei dem Lieferanten bestellt werden!
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                                
                                        <select class="float-right form-control-sm" v-model="params.per_page">
                                            <option value="25">25 Artikel</option>
                                            <option value="50">50 Artikel</option>
                                            <option value="75">75 Artikel</option>
                                            <option value="100">100 Artikel</option>
                                            <option value="125">125 Artikel</option>
                                            <option value="150">150 Artikel</option>
                                        </select>
                                        <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-success card-outline" style="height: 45vh">
                            <div class="card-header">
                                <h2 class="card-title">Bestellte Artikel</h2>
                                <div class="card-tools">
                                    <button class="btn btn-primary btn-sm" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Artikelnummer</th>
                                            <th>Artikelname</th>
                                            <th>Hersteller</th>
                                            <!-- <th>Preis</th> -->
                                            <th>Anzahl</th>
                                            <th>MHD</th>
                                            <th>Label</th>
                                            <th>Aktion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in order.items" :key="article.id">
                                            <td>{{ article.articleordernumber }}</td>
                                            <td>
                                                <input v-if="article.type == 'free'" type="text" v-model="order.items[index].name" class="form-control form-control-sm" />
                                                <span v-else>{{ article.name }}</span>
                                            </td>
                                            <td>
                                                <span v-if="article.type != 'free' && article.producer != null">{{ article.producer.name }}</span>
                                                <span v-else>-</span>
                                            </td>
                                            <!-- <td>
                                                <input v-if="article.type == 'free'" type="number" v-model="order.items[index].purchase_price" class="form-control form-control-sm" />
                                                <span v-else>{{ (article.price / 100) * (100 - parseFloat(order.dealer.discount)) | toCurrency }}</span>
                                            </td> -->
                                            <td><input type="number" class="form-control form-control-sm" v-model="order.items[index].qty" /></td>
                                            <td>
                                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"><input type="checkbox" :id="'mhdSwitch-' + index" class="custom-control-input" v-model="order.items[index].mhd"><label :for="'mhdSwitch-' + index" class="custom-control-label"></label></div>
                                            </td>
                                            <td>
                                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"><input type="checkbox" :id="'labelSwitch-' + index" class="custom-control-input" v-model="order.items[index].printLabel"><label :for="'labelSwitch-' + index" class="custom-control-label"></label></div>
                                            </td>
                                            <td><button type="button" class="btn btn-danger btn-sm" @click="deleteArticle(index)"><i class="fas fa-trash"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer text-right">
                                <!-- <p><strong>Bestellwert:</strong> {{ calculateEK() | toCurrency}}</p> -->
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <div class="row">
                    <div class="col-md-12 text-right" v-if="notEmptyObject(order)">
                        
                        <!-- <button type="button" class="btn btn-primary mr-1" @click="editOrder(false)" :disabled="order.items.length <= 0" >Speichern</button> -->
                        <div class="btn-group">
                        <button type="button" class="btn btn-primary" @click="editOrder(false)" :disabled="order.items.length <= 0" >Speichern</button>
                        <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu" role="menu" style="">
                            <a class="dropdown-item" href="#" @click="editOrder(false)" :disabled="order.items.length <= 0">Speichern</a>
                            <a class="dropdown-item" href="#" @click="createOffer()" :class="(order.items.length <= 0 || order.dealer.customer == null) ? 'disabled' : ''">Speichern & Abrechnen</a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "SupplierOrdersDetails",
    title: "Lieferantenbestellung anpassen",

    data(){
        return {
            producers: {},
            order: {
                dealer:{},
                dealer_id: '',
                comment: '',
                //printLabel: 1,
                wishdate: null,
                items: [],
            },
            articles: {},
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'desc',
                per_page: 25,
                producer_id: '',
            },
            search: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },

        'order.items': {
            handler () {
                this.calculateEK();
            },
            deep: true
        }
    },

    methods:{
        createOffer(){
            this.axios
            .put("/dealer-orders/" + this.$route.params.id, 
                this.order,
            )
            .then(() => {

                this.axios
                .post("dealer-orders/convert", {
                    'dealer_order_id': this.order.id,
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Bestellung erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$router.push({name: 'offers.details', params:{id: response.data.data.id}});
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        calculateEK(){
            var sum = 0;

            if(this.order.items.length > 0){
                this.order.items.forEach(function (item){
                    if(item.type == 'article'){
                        sum += (((item.price / 100) * (100 - this.order.dealer.discount)) * item.qty)
                    }
                    if(item.type == 'free'){
                        sum += (item.purchase_price * item.qty)
                    }
                },this);
            }

            return sum;

        },

        addFreeArticle(){
            this.order.items.push({
                han: 'FP',
                articleordernumber: 'FP',
                qty: '1',
                purchase_price: 0,
                article_id: 0,
                article_detail_id: 0,
                name: 'Freie Position',
                type: 'free',
                deposit_type: '-none',
            })
        },

        
        editOrder(){
            this.axios
            .put("/dealer-orders/" + this.$route.params.id, 
                this.order,
            )
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Bestellung erstellt",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                    this.$router.push({name: 'dealer-orders.index'});

            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        deleteArticle(index){
            this.$delete(this.order.items, index);
        },

        addArticle(index){
            var article = this.order.items.find(item => item.ordernumber == this.articles.data[index].ordernumber);
            if(article == undefined)
            {
                this.order.items.push({
                    articleordernumber: this.articles.data[index].ordernumber,
                    name: this.generateName(index),
                    producer: this.articles.data[index].article.producer.name,
                    price: this.articles.data[index].price,
                    purchase_price: this.articles.data[index].purchase_price,
                    qty: 1,
                    han: this.articles.data[index].han,
                    article_detail_id: this.articles.data[index].id,
                    article_id: this.articles.data[index].article_id,
                    type: 'article'
                });    
            }
            else
            {
                article.qty += 1;
            }
        },

        loadDealerOrder(){
            this.axios
                .get("/dealer-orders/" + this.$route.params.id)
                .then((response) => {
                    this.order = response.data.data;
                    this.params.dealer_id = this.order.dealer_id;
                    this.getAllArticles();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers", {
                    params: {
                        per_page: 1000
                    }
                })
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getAllArticles(page = 1) {
            this.axios
                .get("/details", {
                    params: {
                        page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },

    },

    created(){
        // this.loadDealer();
        this.getProducers();
        this.loadDealerOrder();
    }

}
</script>

<style>

</style>